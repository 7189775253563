.gamePlayAreaContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-width: 1310px;
}
.dashboard {
  min-height: 175px;
  background: #eaeaea;
  margin: 10px;
  border-radius: 10px;
  border: 3px solid;
  min-width: 1310px;
}
.currentTab {
  display: flex;
  width: 100%;
  max-height: calc(100% - 195px);
  flex: 1;
}
.centerWrapper {
  flex: 1;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 550px;
}

.sectionTitle {
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 2px;
}

.generation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  min-width: fit-content;
}
.customers {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  min-width: fit-content;
}

.generation > button,
.customers > button {
  margin-top: 5px;
}

.setupScreen {
  width: 900px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
