.presetInnerCard {
  background-color: white;
  border: 2px solid black;
  border-radius: 10px;
  min-width: 500px;
  padding: 20px;
}

.selectAPreset {
  display: flex;
}
.input {
  margin: 5px;
}
.button {
  margin: 5px;
}

.listOfPresets {
  display: flex;
  flex-direction: column;
}

.presetNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.presetButtons {
  display: flex;
  justify-content: space-evenly;
  margin-left: 10px;
}
.presetButton {
  margin: 2px;
}
