.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 10px 0px 0px 10px;
}
.selectSetting {
  width: 200px;
}
.selectSetting > div {
  border-radius: 7px !important;
}
.button {
  margin-right: 3px;
  border-radius: 10px;
}
.button:hover {
  box-shadow: 0px 0px 10px rgb(0, 0, 0);
}
