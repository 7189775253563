.container{

    display: flex;
    flex-direction: column;
    align-items: center;
    margin:20px;
    color: white;
    gap:10px;

}
.teamLabel {
    color: white;
    border-radius: 10px;
    padding: 5px;
    font-weight: bold;
    border: 2px solid white;
}

.container > h2 {
    margin: 10px;
    text-align: center;
    color: white;
}

.container> div >h3{
    color:white;
}

.offerDetails{
    text-align: center;
    font-size: 16px;
    padding:10px;
    border: solid 2px white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap:15px;
    width: 300px;
}
.offerDetails > p {
    font-size: 16px;
}
.teamLabelGroup{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.infoLabel{
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 300;
    text-align: center;

}

.infoText{
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 28px;
}

.button{

    width: 200px;
    height: 50px;
    border: white 2px solid;
    border-radius: 10px;
}
.acceptButton{
    margin-top: 10px;
    background-color: darkgreen;
}

.rejectButton{
    margin-top: 10px;
    background-color: darkred;
}