p {
  font-size: 12px;
}

.controlAreaContainer {
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  margin: 0px 10px 0px 5px;
}

.controlAreaContainer > h1 {
  color: white;
  margin: 10px;
}

.setupArea {
  width: min-content;
}

.eventLog {
  min-width: 50%;
}
