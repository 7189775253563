.container {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 15px;
  width: min-content;
  padding: 20px;
  margin: 10px;
  border: 4px solid;
  min-width: 500px;
}
.container > h5 {
  width: 80%;
  align-self: center;
}

.container > label {
  margin: 20px 0px 5px 0px;
}
.straplineInput {
  width: 500px;
}
.strategyStatementInput {
  width: 500px;
  height: 150px !important;
  max-height: 250px;
}
.submit {
  margin-top: 20px;
}
.submitMessage {
  color: green;
  margin-top: 10px;
}
.sectionTitle {
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 2px;
}
