.container {
  position: relative;
  width: 100%;
  margin: 20px 10px 5px 10px;
}

.line {
  width: 100%;
  position: absolute;
  left: 50px;
  opacity: 0;
  top: 10px;
  border-top-width: 3px;
  border-top-color: black;
  z-index: -1;
}

.roundHeading {
  border: 2px solid rgb(255, 255, 255);

  /* background-color: rgb(197, 197, 197); */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.603);
  padding: 5px 10px;
  border-radius: 10px;
  width: 130px;
  min-height: 40px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.roundHeading > h3 {
  font-weight: bold;
  color: white;
}
