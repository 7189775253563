.logsHeader {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: 130px;
  align-items: center;
}

.logsHeader > h2 {
  color: white;
  margin-top: 20px;
  margin-bottom: 10px;
}
.logs {
  display: flex;
  flex: 1;
  width: 100%;
}

.logColumn {
  display: flex;
  flex-direction: column;

  height: 70vh;
  width: 50%;
}
.logColumn > h3 {
  text-align: center;
  color: white;
}
