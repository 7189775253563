.timmerControls {
  display: flex;
  margin: 5px;
  position: absolute;
  top: 6px;
  right: 6px;
}

.mainContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.innerContainer {
  display: flex;
  width: 100%;

  justify-content: space-evenly;
}
.pageHeading {
  font-weight: bold;
  color: white;
}

.gameRoundContainer {
  margin-top: 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gameActionsContainer {
  padding-left: 140px;
  min-width: 750px;
}

.roundControls {
  display: flex;
  justify-items: flex-start;
  width: 100%;
  margin-top: 20px;
}
.roundControls .button {
  margin: 5px;
}

.presetContainer {
  padding-top: 100px;
}
