.container {
  width: 100%;
  position: relative;
  overflow: auto;

}
.sectionTitle {
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 2px;
}
.close {
  color: white;

  font-weight: bold;
  margin-right: 20px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0px;
}
.teamStatsContainer {

}

.teamStatRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 4px solid black;
  border-radius: 15px;
  background-color: whitesmoke;
}
.teamStatRow > h2 {
  font-weight: bold;
}
.teamStatRow > h4 {
  color: black;
}
.teamAssetRow {
  display: flex;
  width: 100%;
  overflow: auto;
}
