.sectionTitle {
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 2px;
}

.noAssets {
  color: white;
  margin-top: 50px;
}

.upgradeOptions {
  font-size: 14px;
  color: white;
  cursor: pointer;
}
