.sectionTitle {
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 2px;
}
.switchView{
  position: absolute;
  left: 50px;
  z-index: 3;
}
.centerWrapper {
  flex: 1;

  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 550px;
}
.logoContainer {
  background: white;
  padding: 15px 20px;
  margin: 10px;
  border-radius: 20px;
  max-width: 50%;
}
.logo {
  max-width: 100%;
  max-height: 100%;
}

.auctionHeadings {
  margin: 23px 0px 5px 0px;
  color: white;
  text-align: center;
}
.auctionOnSaleNext {
  min-height: 40%;
}

.auctionComingUp {
  display: flex;
  max-width: 100%;
  overflow-x: auto;
}

.tradingWrapper .sectionTitle {
  margin-right: 20px;
}
