.controlAreaInner {
  display: flex;
  /* background-image: linear-gradient(310deg, #2f367a 23%, #004e92 73%); */
  min-height: fit-content;
}

.leftControlColumns {
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.leftTwoControlColumns {
  display: flex;
  height: fit-content;
}

.controlColumn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 3px;
  height: fit-content;
}
.controlColumn:nth-last-child(1) {
  min-width: 330px;
  max-width: 380px;
}
.lastControlColumn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 3px;
  height: fit-content;
  min-width: 350px;
  max-width: 400px;
}

.systemStats {
  margin: 5px;
  display: flex;
  justify-content: space-evenly;
}

.systemStats > h4 {
  color: white;
}

.tradeTablesContainer {
  display: flex;
  width: 100%;
}
.tradesTable {
  display: flex;
  flex-direction: column;
  min-width: 25%;
}
.tradesTable > h3 {
  text-align: center;
  margin-bottom: 0px;
}

.tradesTable > div {

  border: solid rgb(0, 42, 98) 2px;
}

.settingContainer {
  margin:10px 5px 5px 5px;
  background: white;
  margin: 10px 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  height: min-content;
  border-radius: 11px;
}
.settingContainer > h2{
  margin:10px 5px 10px 5px;
  color: white;
  text-align: center;
}

.logoContainer {
  background: white;
  padding: 5px 10px;
  border-radius: 20px;
  max-width: 70%;
  align-self: center;
}
.logoContainer > h2 {
  text-align: center;
  color: #2f367a;
  font-weight: bold;
  margin-bottom: 8px;
}
.logo {
  max-width: 100%;
  max-height: 100%;
}

.timmerControls {
  display: flex;
  margin: 5px;
}
