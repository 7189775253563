.container {
  position: relative;
  width: 100%;
  margin: 3px 10px;
}

.actionContainer {
  border: 1px solid rgb(255, 255, 255);
  color: white;
  /* background-color: rgb(32, 84, 151); */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.603);
  padding: 3px 10px 3px 20px;
  border-radius: 10px;
  width: 100%;
  min-height: 30px;
  display: flex;
  flex-direction: column;
}

.topRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.topRow > h3 {
  color: white;
}
.scenarioSelectContainer {
  display: flex;
  align-items: center;
}

.sharePriceContainer {
  margin-top: 5px;
  margin-left: 5px;
}

.scenarioSelectContainer > div > div > div {
  border-radius: 7px !important;
}
.info {
  margin-left: 5px;
}

.inputArea {
  margin-left: auto;
}

.actionParameterArrow {
  font-size: 20px;
}

.delete {
  justify-self: flex-end;
  margin-left: 5px;
}
.delete:hover {
  color: rgb(196, 0, 0);
}

.warning {
  background-color: red;
  width: 32px;
  height: 32px;
  text-align: center;
  padding-top: 3px;
}
