.container {
  width: 100%;
  position: relative;
  overflow: auto;
}

.sectionTitle {
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 2px;
}

.assetDisplay {
  display: flex;
  overflow: auto;
  min-height: 500px;
}
.upgradeAssetColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.asset {
  min-height: 255px;
}
.upgradeButtonRow {
  display: flex;
}
.upgradeButtonRow > button {
  color: white;
  margin: 0px 10px;
}

.downArrow {
  color: white;
  font-size: 20px;
}

.upgradeList {
  height: 300px;
  overflow: auto;
}
.close {
  color: white;

  font-weight: bold;
  margin-right: 20px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0px;
}

.container > p {
  color: white;
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}
