.defaultContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin:10px;
}

.defaultContainer >h3{
    text-align: center;
    color:white;
    margin-top:20px;
}
